import { loggedWorkApi } from "@core/app/api/loggedWorkApi";
import { RootState } from "@core/app/store/store";
import { ShortJob } from "@core/app/types/absenceApiType";
import { CalendarTrip, Job, LoggedWorkItem, LoggedWorkSummary } from "@core/app/types/logworkApiType";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";

export type ColumnTypes = 
        'date'
        | 'job'
        | 'subProject'
        | 'article'
        | 'articleGroup'
        | 'status'
        | 'info'
        | 'duration'
        | 'break'
        | 'qty'
        | 'salary'
        | 'invoice'

interface FilterItem {
    id: string
    label: string
    value: number[] | number | boolean | null
}

interface LoggedWorkState {
    asideWidth: number
    choosedDate: string
    viewType: 'week' | 'month' | 'year'
    loggedWork: LoggedWorkItem[]
    isLoggedWorkLoaded: boolean
    loading: boolean
    tableColumns: { id: number, type: ColumnTypes, label: string, visibility: boolean, width: string, manuallyHidden: boolean, autoHidden: boolean }[]
    jobs: Job[]
    shortJobs: ShortJob[]
    articleGroups: { articleGroupID: number, name: string }[]
    selectedWorks: LoggedWorkItem[]
    summary: LoggedWorkSummary | null
    filters: {
        articles?: FilterItem
        jobs?: FilterItem
        subProjects?: FilterItem
        trips?: FilterItem
        attachments?: FilterItem
        notes?: FilterItem
        // 1 - To approval, 2 - Approved, 3 - Paid
        status?: FilterItem
    } | null,
    quickFiltersUsed: string[]
    trips: CalendarTrip[]
}

const defaultTableColumns = [
    { id: 1, type: 'date', label: 'Date', visibility: true, width: '133px', manuallyHidden: false, autoHidden: false },
    { id: 2, type: 'job', label: 'Job', visibility: true, width: '271px', manuallyHidden: false, autoHidden: false },
    { id: 3, type: 'subProject', label: 'Sub project', visibility: true, width: '170px', manuallyHidden: false, autoHidden: false },
    { id: 4, type: 'article', label: 'Article', visibility: true, width: '271px', manuallyHidden: false, autoHidden: false },
    { id: 5, type: 'articleGroup', label: 'Article group', visibility: true, width: '147px', manuallyHidden: false, autoHidden: false },
    { id: 6, type: 'status', label: 'Status', visibility: true, width: '155px', manuallyHidden: false, autoHidden: false },
    { id: 7, type: 'info', label: 'Info', visibility: true, width: '125px', manuallyHidden: false, autoHidden: false },
    { id: 8, type: 'duration', label: 'Duration', visibility: true, width: '108px', manuallyHidden: false, autoHidden: false },
    { id: 9, type: 'break', label: 'Break', visibility: true, width: '85px', manuallyHidden: false, autoHidden: false },
    { id: 10, type: 'qty', label: 'Qty', visibility: true, width: '85px', manuallyHidden: false, autoHidden: false },
    { id: 11, type: 'salary', label: 'Salary', visibility: true, width: '140px', manuallyHidden: false, autoHidden: false },
    { id: 12, type: 'invoice', label: 'Invoice', visibility: true, width: '140px', manuallyHidden: false, autoHidden: false },
]


const loadSettingsFromLocalStorage = () => {
    const storedSettings = localStorage.getItem('settings');
    if (storedSettings) {
        try {
            const parsedSettings = JSON.parse(storedSettings);
            // Проверяем, существуют ли `columns`, если нет, то используем дефолтное значение
            return {
                columns: parsedSettings.columns ?? defaultTableColumns,
                filters: parsedSettings.filters ?? null,
            };
        } catch {
            console.error('Failed to parse settings from localStorage');
        }
    }
    // Default values
    return { columns: defaultTableColumns, filters: null };
}


const saveSettingsToLocalStorage = (settings: { columns?: LoggedWorkState['tableColumns'], filters?: LoggedWorkState['filters'] | null }) => {
    localStorage.setItem('settings', JSON.stringify(settings));
}

const initialState: LoggedWorkState = {
    asideWidth: 0,
    choosedDate: localStorage.getItem('loggedWork_date') || dayjs().toISOString(),
    viewType: 'month',
    loggedWork: [],
    loading: false,
    isLoggedWorkLoaded: false,
    tableColumns: loadSettingsFromLocalStorage().columns,
    filters: loadSettingsFromLocalStorage().filters,
    jobs: [],
    shortJobs: [],
    selectedWorks: [],
    summary: null,
    quickFiltersUsed: [],
    trips: [],
    articleGroups: []
}


const loggedWorkSlice = createSlice({
    name: 'loggedWork',
    initialState,
    reducers: {
        setAsideWidth(state, action) {
            state.asideWidth = action.payload
        },
        setChoosedDate(state, action) {
            state.choosedDate = action.payload
            localStorage.setItem('loggedWork_date', action.payload)
        },
        setViewType(state, action) {
            state.viewType = action.payload
        },
        updateTableColumns(state, action: PayloadAction<{ type: string; visibility?: boolean; newIndex?: number }>) {
            const { type, visibility, newIndex } = action.payload
            const columnIndex = state.tableColumns.findIndex((col) => col.type === type)

            if (columnIndex !== -1) {
                if (visibility !== undefined) {
                    state.tableColumns[columnIndex].visibility = visibility
                    state.tableColumns[columnIndex].manuallyHidden = !visibility
                }

                if (newIndex !== undefined && newIndex >= 0 && newIndex < state.tableColumns.length) {
                    const [movedColumn] = state.tableColumns.splice(columnIndex, 1)
                    state.tableColumns.splice(newIndex, 0, movedColumn)
                }

                saveSettingsToLocalStorage({ columns: state.tableColumns, filters: state.filters })
            }
        },
        resetColumnsOrder(state) {
            const sortedColumns = [...state.tableColumns].sort((a, b) => a.id - b.id)
            state.tableColumns = sortedColumns

            saveSettingsToLocalStorage({ columns: sortedColumns, filters: state.filters })
        },
        checkWork(state, action: PayloadAction<LoggedWorkItem>) {
            state.selectedWorks = [...state.selectedWorks, action.payload]
        },
        uncheckWork(state, action: PayloadAction<LoggedWorkItem>) {
            const id = action.payload.workHourId
            state.selectedWorks = state.selectedWorks.filter(item => item.workHourId !== id)
        },
        setQuickFilter(state, action: PayloadAction<string>) {
            if (state.quickFiltersUsed.includes(action.payload)) return
            state.quickFiltersUsed.push(action.payload)
        },
        removeQuickFilter(state, action: PayloadAction<string>) {
            state.quickFiltersUsed = state.quickFiltersUsed.filter(f => !f.includes(action.payload))
        },
        setFilter(state, action: PayloadAction<{ id: string; value: number[] | number | boolean | null; label: string }>) {
            if (!state.filters) {
              state.filters = {};
            }
            // @ts-ignore
            state.filters[action.payload.id] = { id: action.payload.id, value: action.payload.value, label: action.payload.label }
            saveSettingsToLocalStorage({ filters: state.filters, columns: state.tableColumns })
        },
        removeFilter(state, action: PayloadAction<string>) {
            // @ts-ignore
            if (state.filters && state.filters[action.payload]) {
                // @ts-ignore
                delete state.filters[action.payload];
                saveSettingsToLocalStorage({ filters: state.filters, columns: state.tableColumns });
            }
        },
        clearFilters(state) {
            state.filters = null
            saveSettingsToLocalStorage({ filters: {}, columns: state.tableColumns })
        },
    },
    extraReducers(builder) {
        builder.addMatcher(loggedWorkApi.endpoints.getData.matchPending, (state) => {
            state.loading = true
            state.isLoggedWorkLoaded = false
        }),
        builder.addMatcher(loggedWorkApi.endpoints.getData.matchRejected, (state) => {
            state.loading = false
            state.isLoggedWorkLoaded = false
        }),
        builder.addMatcher(loggedWorkApi.endpoints.getData.matchFulfilled, (state, action) => {
            const { work, trips } = action.payload
            if (work) {
                const loggedWork = Object.values(work.loggedWork).flat() as LoggedWorkItem[]
                const dateCount: Record<string, number> = {}
                loggedWork.forEach(item => {
                    if (!item.fromDate) return
                    dateCount[item.fromDate] = (dateCount[item.fromDate] || 0) + 1
                })
                state.loggedWork = loggedWork.map((item, index, array) => {
                    if (!item.fromDate) return item
                    const isSecondary = dateCount[item.fromDate] > 1 && array.findIndex(d => d.fromDate === item.fromDate) !== index
                    return {...item, isSecondary}
                })
                state.summary = work.summary
                state.trips = trips
                state.shortJobs = work.jobs

                if (work.groups) {
                    state.articleGroups = Object.values(work.groups)
                }

                const uniqueJobIds = new Set(loggedWork.map((item) => item.jobId))
                const hasArticleGroups = loggedWork.some((item) => item.articleGroupId !== 0)
                const hasSalary = loggedWork.some((item) => item.salary !== 'hidden')
                const hasInvoice = loggedWork.some((item) => item.invoice !== 'hidden')
                const hasChildProjects = loggedWork.some((item) => item.childProjectId !== 0)

                state.tableColumns.forEach((column) => {
                    if (!column.manuallyHidden) {
                        switch (column.type) {
                            case 'job':
                                column.autoHidden = uniqueJobIds.size <= 1
                                break
                            case 'articleGroup':
                                column.autoHidden = !hasArticleGroups
                                break
                            case 'salary':
                                column.autoHidden = !hasSalary
                                break
                            case 'invoice':
                                column.autoHidden = !hasInvoice
                                break
                            case 'subProject':
                                column.autoHidden = !hasChildProjects
                                break
                        }
                    }

                    column.visibility = !column.autoHidden && !column.manuallyHidden
                })

                saveSettingsToLocalStorage({ columns: state.tableColumns, filters: state.filters })
            }
            
            state.loading = false
            state.isLoggedWorkLoaded = true
        })
        
    }
})

export default loggedWorkSlice.reducer
export const { setAsideWidth, setChoosedDate, setViewType, updateTableColumns, checkWork, uncheckWork, resetColumnsOrder, setQuickFilter, removeQuickFilter, setFilter, removeFilter, clearFilters } = loggedWorkSlice.actions
export const storeAsideWidth = (state: RootState) => state.loggedWork.asideWidth
export const storeChoosedDate = (state: RootState) => state.loggedWork.choosedDate
export const storeViewType = (state: RootState) => state.loggedWork.viewType
export const storeLoggedWork = (state: RootState) => state.loggedWork.loggedWork
export const storeLoading = (state: RootState) => state.loggedWork.loading
export const storeTableColumns = (state: RootState) => state.loggedWork.tableColumns
export const storeJobs = (state: RootState) => state.loggedWork.jobs
export const storeShortJobs = (state: RootState) => state.loggedWork.shortJobs
export const storeIsLoggedWorkLoaded = (state: RootState) => state.loggedWork.isLoggedWorkLoaded
export const storeSelectedWorks = (state: RootState) => state.loggedWork.selectedWorks
export const storeSummary = (state: RootState) => state.loggedWork.summary
export const storeQuickFiltersUsed = (state: RootState) => state.loggedWork.quickFiltersUsed
export const storeFilters = (state: RootState) => state.loggedWork.filters
export const storeTrips = (state: RootState) => state.loggedWork.trips
export const storeArticleGroups = (state: RootState) => state.loggedWork.articleGroups
