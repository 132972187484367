import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@core/app/store/store";

interface FilterItem {
    id: string;
    label: string;
    value: number[] | number | boolean | null;
}

interface GroupingState {
    groupBy: string;
    order: string;
}

interface FilterState {
    filters: {
        busy?: FilterItem;
        project?: FilterItem;
        status?: FilterItem;
    } | null;
    quickFiltersUsed: string[];
    grouping: GroupingState;
}

const loadStateFromLocalStorage = () => {
    const storedFilters = localStorage.getItem("simpleFilters");
    const storedGrouping = localStorage.getItem("groupingState");
    console.log('🗃️ loadStateFromLocalStorage: groupingState from localStorage:', storedGrouping);
    let filters = null;
    let grouping: GroupingState = { groupBy: "requestStatus", order: "ascending" };

    if (storedFilters) {
        try {
            filters = JSON.parse(storedFilters);
        } catch {
            console.error("❌ Failed to parse filters from localStorage");
        }
    }

    if (storedGrouping) {
        try {
            grouping = JSON.parse(storedGrouping);
            console.log('✅ loadStateFromLocalStorage: Parsed grouping:', grouping);
        } catch {
            console.error("❌ Failed to parse grouping from localStorage");
        }
    }

    return { filters, grouping };
};


const saveFiltersToLocalStorage = (filters: FilterState["filters"]) => {
    localStorage.setItem("simpleFilters", JSON.stringify(filters));
};

const saveGroupingToLocalStorage = (grouping: GroupingState) => {
    localStorage.setItem("groupingState", JSON.stringify(grouping));
};

const initialState: FilterState = {
    ...loadStateFromLocalStorage(),
    quickFiltersUsed: [],
};

const simpleFilterSlice = createSlice({
    name: "simpleFilter",
    initialState,
    reducers: {
        setFilter(
            state,
            action: PayloadAction<{
                id: "busy" | "project" | "status";
                value: number[] | number | boolean | null;
                label: string;
            }>
        ) {
            if (!state.filters) {
                state.filters = {};
            }
            state.filters[action.payload.id] = {
                id: action.payload.id,
                value: action.payload.value,
                label: action.payload.label,
            };
            saveFiltersToLocalStorage(state.filters);
        },
        storeFilters(
            state,
            action: PayloadAction<{
                id: "busy" | "project" | "status";
                value: number[] | number | boolean | null;
                label: string;
            }>
        ) {
            if (!state.filters) {
                state.filters = {};
            }
            state.filters[action.payload.id] = {
                id: action.payload.id,
                value: action.payload.value,
                label: action.payload.label,
            };
            saveFiltersToLocalStorage(state.filters);
        },
        removeFilter(state, action: PayloadAction<'busy' | 'project' | 'status'>) {
            if (state.filters && state.filters[action.payload]) {
                delete state.filters[action.payload];
                saveFiltersToLocalStorage(state.filters);
            }
        },
        storeQuickFiltersUsed(state, action: PayloadAction<string>) {
            if (!state.quickFiltersUsed.includes(action.payload)) {
                state.quickFiltersUsed.push(action.payload);
            }
        },
        clearFilters(state) {
            state.filters = null;
            saveFiltersToLocalStorage(state.filters);
        },
        setGrouping(
            state,
            action: PayloadAction<{ groupBy: string; order: string }>
        ) {
            console.log('🟢 Redux: setGrouping called with:', action.payload);
            state.grouping = action.payload;
            saveGroupingToLocalStorage(state.grouping);
        },
        
        clearGrouping(state) {
            console.log('🟡 Redux: clearGrouping called');
            state.grouping = { groupBy: "", order: "" };  // 🟢 Очищення до порожніх значень
            saveGroupingToLocalStorage(state.grouping);
            console.log('🟡 Redux: grouping after clear:', state.grouping);
        },
        
    },
});

export default simpleFilterSlice.reducer;
export const {
    setFilter,
    storeFilters,
    removeFilter,
    storeQuickFiltersUsed,
    clearFilters,
    setGrouping,
    clearGrouping,
} = simpleFilterSlice.actions;

export const selectFilters = (state: RootState) => state.simpleFilter.filters;
export const selectQuickFiltersUsed = (state: RootState) =>
    state.simpleFilter.quickFiltersUsed;
export const selectGrouping = (state: RootState) => state.simpleFilter.grouping;
