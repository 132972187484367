import {
    BasicInfo,
    CreateAttachment,
    CreateAvatar,
    CreateCertificate,
    CreateDriverLicense,
    CreateEducation,
    CreateIndustry,
    CreateLocation,
    CreateProfilePicture,
    CreateProjectExperience,
    CreateReference,
    CreateRelative,
    CreateSkill,
    CreateWorkExperience,
    GetAgreement,
    GetAttr,
    GetSharedFiles,
    HeaderInfo,
    ProfileInfo,
    ProfileSettings,
    SearchCity,
    SearchOccupation,
    SearchRegion,
    UpdateAttr,
    UploadCV,
} from '@core/app/types/profileApiType'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const profileApi = createApi({
    reducerPath: 'profileApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.ENV === 'local' ? `${process.env.API_URL}/api` : `${window.location.origin}/api`,
        prepareHeaders: (headers) => {
            const token = document.getElementsByName('csrf-token')[0]?.getAttribute('content')
            if (token) headers.set('X-CSRF-TOKEN', token)

            const customCorporation = document.getElementById('__custom_route_data')?.getAttribute('data-corpId')
            if (customCorporation) headers.set('X-CORP-CONTEXT', customCorporation)

            return headers
        },
        credentials: process.env.ENV === 'local' ? 'include' : 'same-origin',
    }),
    tagTypes: ['Profile', 'Attributes'],
    endpoints: (builder) => ({
        getHeaderInfo: builder.query<HeaderInfo, any>({
            query: () => ({
                url: '/profile',
                method: 'GET',
            }),
            providesTags: ['Profile'],
        }),
        getBasicInfo: builder.query<ProfileInfo, void>({
            query: () => ({
                url: '/profile/info',
                method: 'GET',
            }),
            providesTags: ['Profile'],
        }),
        getSettings: builder.query<ProfileSettings, void>({
            query: () => ({
                url: '/profile/list/settings',
                method: 'GET',
            }),
        }),
        updateGeneralFeedback: builder.mutation<void, { rating?: number | null; message: string; url: string }>({
            query: (body) => ({
                url: '/feedback/general',
                method: 'POST',
                body,
            }),
            // invalidatesTags: ['Profile'],
        }),
        createProfileLang: builder.mutation<void, { data: { languageId: number; proficiencyId: number } }>({
            query: (body) => ({
                url: '/profile/language/create',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        updateProfileLang: builder.mutation<
            void,
            { data: { languageId: number; proficiencyId: number; languageSkillId: number } }
        >({
            query: (body) => ({
                url: `/profile/language/${body.data.languageSkillId}/update`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        deleteProfileLang: builder.mutation<void, { id: number }>({
            query: ({ id }) => ({
                url: `/profile/language/${id}/delete`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Profile'],
        }),
        searchWorkExperienceTitle: builder.query<
            { data: { id: number; name: string }[]; statusCode: number },
            { query: string }
        >({
            query: ({ query }) => ({
                url: `/profile/experience/search?query=${query}`,
                method: 'GET',
            }),
        }),
        setWorkExperience: builder.mutation<void, CreateWorkExperience>({
            query: (body) => ({
                url: '/profile/experience/create',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        deleteWorkExperience: builder.mutation<void, { id: number }>({
            query: ({ id }) => ({
                url: `/profile/experience/${id}/delete`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Profile'],
        }),
        updateWorkExperience: builder.mutation<void, CreateWorkExperience>({
            query: (body) => ({
                url: `/profile/experience/${body.data.id}/update`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        updateBasicInfo: builder.mutation<void, BasicInfo>({
            query: (body) => ({
                url: '/profile/update',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        updateBasicInfoForProfile: builder.mutation<void, BasicInfo>({
            query: (body) => ({
                url: '/profile/update?profile',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        searchEducationalInstitution: builder.query<
            { data: { id: number; name: string }[]; statusCode: number },
            { query: string }
        >({
            query: ({ query }) => ({
                url: `/profile/education/search?query=${query}`,
                method: 'GET',
            }),
        }),
        setEducation: builder.mutation<void, CreateEducation>({
            query: (body) => ({
                url: '/profile/education/create',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        updateEducation: builder.mutation<void, CreateEducation>({
            query: (body) => ({
                url: `/profile/education/${body.data.id}/update`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        deleteEducation: builder.mutation<void, { id: number }>({
            query: ({ id }) => ({
                url: `/profile/education/${id}/delete`,
            }),
            invalidatesTags: ['Profile'],
        }),
        setRelative: builder.mutation<void, CreateRelative>({
            query: (body) => ({
                url: '/profile/relative/create',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        updateRelative: builder.mutation<void, CreateRelative>({
            query: (body) => ({
                url: `/profile/relative/${body.data.id}/update`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        deleteRelative: builder.mutation<void, { id: number }>({
            query: ({ id }) => ({
                url: `/profile/relative/${id}/delete`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Profile'],
        }),
        setProjectExperience: builder.mutation<void, CreateProjectExperience>({
            query: (body) => ({
                url: '/profile/project-experience/create',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        updateProjectExperience: builder.mutation<void, CreateProjectExperience>({
            query: (body) => ({
                url: `/profile/project-experience/${body.data.id}/update`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        deleteProjectExperience: builder.mutation<void, { id: number }>({
            query: ({ id }) => ({
                url: `/profile/project-experience/${id}/delete`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Profile'],
        }),
        setCertificate: builder.mutation<void, CreateCertificate>({
            query: (body) => ({
                url: '/profile/course/create',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        searchCourse: builder.query<{ data: { id: number; name: string }[]; statusCode: number }, { query: string }>({
            query: ({ query }) => ({
                url: `/profile/course/search?query=${query}`,
                method: 'GET',
            }),
        }),
        deleteCourseFile: builder.mutation<void, { id: number }>({
            query: ({ id }) => ({
                url: `/profile/course/${id}/file/delete`,
                method: 'DELETE',
            }),
        }),
        updateCourseFile: builder.mutation<
            void,
            { data: { attachment: { base64: string; name: string; ext: string; id: number } } }
        >({
            query: (body) => ({
                url: `/profile/course/${body.data.attachment.id}/file/insert`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        updateCertificate: builder.mutation<void, CreateCertificate>({
            query: (body) => ({
                url: `/profile/course/${body.data.id}/update`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        deleteCertificate: builder.mutation<void, { id: number }>({
            query: ({ id }) => ({
                url: `/profile/course/${id}/delete`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Profile'],
        }),
        setReference: builder.mutation<void, CreateReference>({
            query: (body) => ({
                url: '/profile/reference/create',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        updateReference: builder.mutation<void, CreateReference>({
            query: (body) => ({
                url: `/profile/reference/${body.data.id}/update`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        deleteReference: builder.mutation<void, { id: number }>({
            query: ({ id }) => ({
                url: `/profile/reference/${id}/delete`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Profile'],
        }),
        setDriverLicense: builder.mutation<void, CreateDriverLicense>({
            query: (body) => ({
                url: '/profile/driver-licence/add',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        searchSkills: builder.query<any, { query: string }>({
            query: ({ query }) => ({
                url: `/profile/skill/search?query=${query}`,
                method: 'GET',
            }),
        }),
        setSkill: builder.mutation<void, CreateSkill>({
            query: (body) => ({
                url: '/profile/skill/create',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        updateSkill: builder.mutation<void, { id: number; data: { rating: number } }>({
            query: (body) => ({
                url: `/profile/skill/${body.id}/update`,
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['Profile'],
        }),
        deleteSkill: builder.mutation<void, { id: number }>({
            query: ({ id }) => ({
                url: `/profile/skill/${id}/delete`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Profile'],
        }),
        uploadCv: builder.mutation<
            { data: { sections: { [key: string]: boolean }; successPercent: number }; statusCode: number },
            UploadCV
        >({
            query: (body) => ({
                url: '/profile/upload/cv',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        uploadCvV2: builder.mutation<
            { data: { sections: { [key: string]: boolean }; successPercent: number }; statusCode: number },
            UploadCV
        >({
            query: (body) => ({
                url: '/profile/upload/cv?v2',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),

        triggerCv: builder.mutation<{ data: any }, void>({
            query: () => ({
                url: '/profile/trigger/cv',
                method: 'POST',
            }),
            invalidatesTags: ['Profile'],
        }),

        connectSocket: builder.mutation<
            { success: boolean; token: string; url: string; candidateId: number; subscriptionId: number },
            void
        >({
            query: () => ({
                url: '/profile/connect/socket',
                method: 'POST',
            }),
            transformResponse: (response: {
                statusCode: number
                success: boolean
                message: string
                data: { success: boolean; token: string; url: string; candidateId: number; subscriptionId: number }
            }) => response.data,
        }),

        setProfilePicture: builder.mutation<void, CreateProfilePicture>({
            query: (body) => ({
                url: '/profile/picture/add',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        getSharedFiles: builder.query<GetSharedFiles, void>({
            query: () => ({
                url: '/profile/documents',
                method: 'GET',
            }),
        }),
        setAttachment: builder.mutation<void, CreateAttachment>({
            query: (body) => ({
                url: '/profile/attachment/create',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        deleteAttachment: builder.mutation<void, { id: number }>({
            query: ({ id }) => ({
                url: `/profile/attachment/${id}/delete`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Profile'],
        }),
        setLocation: builder.mutation<void, CreateLocation>({
            query: (body) => ({
                url: '/profile/location/create',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        searchRegion: builder.query<SearchRegion, { id: number }>({
            query: ({ id }) => ({
                url: `/profile/location/region/${id}`,
                method: 'GET',
            }),
        }),
        searchCity: builder.query<SearchCity, { id: number }>({
            query: ({ id }) => ({
                url: `/profile/location/city/${id}`,
                method: 'GET',
            }),
        }),
        deleteLocation: builder.mutation<void, { id: number }>({
            query: ({ id }) => ({
                url: `/profile/location/${id}/delete`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Profile'],
        }),
        searchOccupation: builder.query<SearchOccupation, { id: number }>({
            query: ({ id }) => ({
                url: `/profile/branch/${id}/occupation`,
                method: 'GET',
            }),
        }),
        setIndustry: builder.mutation<void, CreateIndustry>({
            query: (body) => ({
                url: '/profile/branch/create',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        deleteIndustry: builder.mutation<void, { id: number }>({
            query: ({ id }) => ({
                url: `/profile/branch/${id}/delete`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Profile'],
        }),
        setAvatar: builder.mutation<void, CreateAvatar>({
            query: (body) => ({
                url: '/profile/picture/add',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        deleteAvatar: builder.mutation<void, void>({
            query: () => ({
                url: '/profile/picture/delete',
                method: 'DELETE',
            }),
            invalidatesTags: ['Profile'],
        }),
        requestEmailVerify: builder.mutation<void, void>({
            query: () => ({
                url: '/profile/verify-request',
                method: 'POST',
            }),
            invalidatesTags: ['Profile'],
        }),
        verifyEmail: builder.mutation<void, { data: { code: string } }>({
            query: (body) => ({
                url: '/profile/verify-email',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        getAttributes: builder.query<GetAttr, void>({
            query: () => ({
                url: '/profile/attribute/list',
                method: 'GET',
            }),
            providesTags: ['Attributes'],
        }),
        getAttributesForApply: builder.query<GetAttr, { id: string }>({
            query: ({ id }) => ({
                url: `/profile/job/${id}/attributes`,
                method: 'GET',
            }),
            providesTags: ['Attributes'],
        }),
        setAttributes: builder.mutation<void, UpdateAttr>({
            query: (body) => ({
                url: `/profile/attribute/${body.data.id}/update`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Attributes', 'Profile'],
        }),
        setNoFields: builder.mutation<
            void,
            {
                data: {
                    noCourse?: boolean
                    noEducation?: boolean
                    noExperience?: boolean
                    noProjectExperience?: boolean
                }
            }
        >({
            query: (body) => ({
                url: '/profile/fields/missing',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        getAgreement: builder.query<GetAgreement, void>({
            query: () => ({
                url: '/profile/agreement',
                method: 'GET',
            }),
        }),
        acceptAgreement: builder.mutation({
            query: () => ({
                url: '/profile/terms/accept',
                method: 'POST',
            }),
            invalidatesTags: ['Profile'],
        }),
        setPassword: builder.mutation<void, { data: { password: string } }>({
            query: (body) => ({
                url: '/profile/settings/password/set',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
        sendSMS: builder.mutation<
            void,
            { data: { mobilePhone: { prefix: string; number: string }; 'g-recaptcha-response': string } }
        >({
            query: (body) => ({
                url: '/profile/settings/send-sms',
                method: 'POST',
                body,
            }),
        }),
        validateSMS: builder.mutation<void, { data: { code: string; enableMFA: boolean } }>({
            query: (body) => ({
                url: '/profile/settings/validate-sms',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Profile'],
        }),
    }),
})

export const {
    useGetBasicInfoQuery,
    useGetHeaderInfoQuery,
    useGetSettingsQuery,
    useCreateProfileLangMutation,
    useUpdateGeneralFeedbackMutation,
    useUpdateProfileLangMutation,
    useDeleteProfileLangMutation,
    useSearchWorkExperienceTitleQuery,
    useSetWorkExperienceMutation,
    useDeleteWorkExperienceMutation,
    useUpdateWorkExperienceMutation,
    useUpdateBasicInfoMutation,
    useUpdateBasicInfoForProfileMutation,
    useSearchEducationalInstitutionQuery,
    useSetEducationMutation,
    useUpdateEducationMutation,
    useDeleteEducationMutation,
    useSetRelativeMutation,
    useUpdateRelativeMutation,
    useDeleteRelativeMutation,
    useSetProjectExperienceMutation,
    useSetCertificateMutation,
    useSearchCourseQuery,
    useDeleteCourseFileMutation,
    useUpdateCertificateMutation,
    useDeleteCertificateMutation,
    useUpdateCourseFileMutation,
    useUpdateProjectExperienceMutation,
    useDeleteProjectExperienceMutation,
    useSetReferenceMutation,
    useUpdateReferenceMutation,
    useDeleteReferenceMutation,
    useSetDriverLicenseMutation,
    useSearchSkillsQuery,
    useUploadCvMutation,
    useUploadCvV2Mutation,
    useTriggerCvMutation,
    useConnectSocketMutation,
    useSetSkillMutation,
    useSetProfilePictureMutation,
    useGetSharedFilesQuery,
    useSetAttachmentMutation,
    useDeleteAttachmentMutation,
    useSetLocationMutation,
    useSearchRegionQuery,
    useSearchCityQuery,
    useDeleteLocationMutation,
    useSearchOccupationQuery,
    useSetIndustryMutation,
    useDeleteIndustryMutation,
    useUpdateSkillMutation,
    useDeleteSkillMutation,
    useSetAvatarMutation,
    useDeleteAvatarMutation,
    useRequestEmailVerifyMutation,
    useVerifyEmailMutation,
    useGetAttributesQuery,
    useSetAttributesMutation,
    useGetAttributesForApplyQuery,
    useSetNoFieldsMutation,
    useGetAgreementQuery,
    useAcceptAgreementMutation,
    useSetPasswordMutation,
    useSendSMSMutation,
    useValidateSMSMutation,
} = profileApi
