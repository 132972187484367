import { Box, Button, Container, Flex, HStack, Image, Link, Text } from '@chakra-ui/react'
import { useGetThemeQuery } from '@core/app/api/globalApi'
import { Header } from '@core/components/Header/Header'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'
import image from '@page/404/assets/images/404.svg'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const Page404 = () => {
    const { t } = useTranslation()
    const { appTheme, isExpired, updateTheme } = useAppTheme()
    const { data: theme } = useGetThemeQuery({}, { skip: isExpired })

    const bg = appTheme?.newSettings?.general?.Background ? `#${appTheme?.newSettings?.general?.Background}` : 'bg.3'

    useEffect(() => {
        if (theme) {
            updateTheme(theme)
        }
    }, [theme])

    return (
        <Flex minH={'100vh'} flexDirection={'column'} bg={bg}>
            <Header />
            <Flex
                as={'main'}
                flexDirection={'column'}
                w={'full'}
                alignItems={'center'}
                justifyContent={'center'}
                h={'full'}
                flex={1}
            >
                <Flex flexDirection={'column'} gap={'10'} alignItems={'center'}>
                    <Image src={image} />
                    <Text
                        fontSize={'2xl'}
                        fontWeight={'semibold'}
                        color={'text.main'}
                        maxW={'218px'}
                        textAlign={'center'}
                    >
                        {t('6652')}
                    </Text>
                    <Button
                        variant={'primary'}
                        minW={'160px'}
                        bg={appTheme ? `#${appTheme?.newSettings?.general?.ButtonBackground}` : 'btn.primary'}
                        color={appTheme ? `#${appTheme?.newSettings?.general?.ButtonText}` : 'text.white'}
                        onClick={() => (location.href = '/')}
                    >
                        {t('6653')}
                    </Button>
                </Flex>
            </Flex>
            <Box as={'footer'} py={'30px'}>
                <Container maxW={'container.xl'}>
                    <Flex
                        w={'full'}
                        justifyContent={'space-between'}
                        flexDirection={{ base: 'column-reverse', xl: 'row' }}
                    >
                        <Text
                            fontSize={'xs'}
                            color={'text.secondary'}
                            mt={{ base: 4, xl: 0 }}
                            textAlign={{ base: 'center', xl: 'start' }}
                        >
                            {t('6654')} {new Date().getFullYear()} - RecMan AS
                        </Text>
                        <HStack gap={6} justifyContent={{ base: 'space-around', xl: 'start' }}>
                            <Link color={'text.secondary'} href={'/user-agreement'}>
                                <Text fontSize={'xs'}>{t('4635')}</Text>
                            </Link>
                            <Link color={'text.secondary'} href={'/privacy-policy'}>
                                <Text fontSize={'xs'}>{t('4636')}</Text>
                            </Link>
                            <Link color={'text.secondary'} href={'/cookie-notice'}>
                                <Text fontSize={'xs'}>{t('4637')}</Text>
                            </Link>
                        </HStack>
                    </Flex>
                </Container>
            </Box>
        </Flex>
    )
}
