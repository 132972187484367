import { logworkApi } from "@core/app/api/logworkApi";
import { RootState } from '@core/app/store/store';
import { CalendarAvailability, CalendarHoliday, CalendarShift, CalendarTrip, CalendarVacation, Job, JobsSettings, LoggedWorkByIdData, LoggedWorkItem, SummaryDetail } from "@core/app/types/logworkApiType";
import { ExtendedLoggedWorkItem } from "@page/logwork/components/LoggedWork/LoggedTable";
import { DayjsType } from "@page/logwork/logwork";
import { Day } from "@page/logwork/types/types";
import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

type Summary = {
    breakQty: number
    grossQty: number
    netQty: number
    qty: number
    salary: {
        [key: string]: number
    }
    invoice: number
    details: SummaryDetail[]
}

interface LogworkState {
    asideWidth: number
    jobs: Job[]
    jobsSettings: JobsSettings | null
    loggedWork: {
        [key: string]: LoggedWorkItem[]
    } | null,
    choosedLoggedWork: {
        [key: string]: LoggedWorkItem[]
    } | null,
    shifts: CalendarShift[]
    availabilities: CalendarAvailability[]
    holidays: CalendarHoliday[]
    trips: CalendarTrip[]
    vacation: CalendarVacation | null
    selectedJob: Job | null,
    selectedJobs: {
        hours: {
            [dayIndex: number]: Job | null;
        },
        manualHours: {
            [dayIndex: number]: Job | null;
        },
        piecework: {
            [dayIndex: number]: Job | null;
        },
        addition: {
            [dayIndex: number]: Job | null;
        },
    },
    selectedWeekData: {
        hours: {
            [dayIndex: string]: Day | null;
        },
        manualHours: {
            [dayIndex: string]: Day | null;
        },
        piecework: {
            [dayIndex: string]: Day | null;
        },
        addition: {
            [dayIndex: string]: Day | null;
        },
    },
    loggedWorkChildProjects: {
        childProjectId: number,
        name: string
    }[]
    loggedWorkJobs: {
        invoiceCurrencyId: number
        jobId: number
        name: string
        salaryCurrencyId: number
    }[]
    childProjects: {
        [key: string]: {
            childProjectId: number,
            name: string
        }[]
    }
    resetTable: boolean
    isSidebarOpened: boolean
    isLogWorkHoursErrors: {
        [key: string]: {
            [key: string]: string[]
        }
    } | null
    summaryStats: Summary | null
    prevSummaryStats: Summary | null
    loggedWorkById: LoggedWorkByIdData | null
    isLoading: { jobs: boolean, loggedWork: boolean, calendar: boolean, loggedWorkById: boolean }
    isRegisterTrackerActive: boolean
    isDeleteTrackerActive: boolean
    isFileAttachmentError: boolean
    selectedDate: { start: DayjsType; end: DayjsType } | null
    workHourId: number,
    loggedItemsToDelete: ExtendedLoggedWorkItem[]
    firstJobYear: number
}

const initialState: LogworkState = {
    asideWidth: 0,
    jobs: [],
    jobsSettings: null,
    loggedWork: null,
    choosedLoggedWork: null,
    shifts: [],
    availabilities: [],
    holidays: [],
    trips: [],
    vacation: null,
    selectedJob: null,
    selectedJobs: {
        manualHours: {},
        piecework: {},
        hours: {},
        addition: {}
    },
    selectedWeekData: {
        manualHours: {},
        piecework: {},
        hours: {},
        addition: {}
    },
    resetTable: false,
    loggedWorkChildProjects: [],
    loggedWorkJobs: [],
    isSidebarOpened: true,
    isLogWorkHoursErrors: null,
    childProjects: {},
    summaryStats: null,
    prevSummaryStats: null,
    loggedWorkById: null,
    isLoading: { jobs: false, loggedWork: false, calendar: false, loggedWorkById: false },
    isRegisterTrackerActive: false,
    isDeleteTrackerActive: false,
    isFileAttachmentError: false,
    selectedDate: null,
    workHourId: 0,
    loggedItemsToDelete: [],
    firstJobYear: dayjs().year(),
}

const logworkSlice = createSlice({
    name: 'logwork',
    initialState,
    reducers: {
        setJobs(state, action) {
            state.jobs = action.payload
        },
        setLoggedWork(state, action) {
            state.loggedWork = action.payload
        },
        setChoosedLoggedWork(state, action) {
            state.choosedLoggedWork = action.payload
        },
        setSelectedJob(state, action) {
            state.selectedJob = action.payload
        },
        setSelectedJobs(state, action) {
            state.selectedJobs = action.payload
        },
        setSelectedWeekData(state, action) {
            state.selectedWeekData = action.payload;
        },
        setResetTable(state, action) {
            state.resetTable = action.payload
        },
        setIsSidebarOpened(state, action) {
            state.isSidebarOpened = action.payload
        },
        handleLogWorkErrors(state, action) {
            state.isLogWorkHoursErrors = action.payload
        },
        setAsideWidth(state, action) {
            state.asideWidth = action.payload
        },
        setIsRegisterTrackerActive(state, action) {
            state.isRegisterTrackerActive = action.payload
        },
        setIsDeleteTrackerActive(state, action) {
            state.isDeleteTrackerActive = action.payload
        },
        setIsFileAttachmentError(state, action) {
            state.isFileAttachmentError = action.payload
        },
        setSelectedDate(state, action) {
            state.selectedDate = action.payload;
        },
        setWorkHourId(state, action) {
            state.workHourId = action.payload
        },
        setLoggedItemsToDelete(state, action) {
            state.loggedItemsToDelete = action.payload
        }
    },
    extraReducers(builder) {
        builder.addMatcher(logworkApi.endpoints.getLoggedWorkById.matchPending, (state) => {
            state.isLoading.loggedWorkById = true;
        })
        builder.addMatcher(logworkApi.endpoints.getLoggedWorkById.matchRejected, (state) => {
            state.isLoading.loggedWorkById = false;
        })
        builder.addMatcher(logworkApi.endpoints.getLoggedWorkById.matchFulfilled, (state, action) => {
            state.loggedWorkById = action.payload;
            state.isLoading.loggedWorkById = false;
        })
        builder.addMatcher(logworkApi.endpoints.getJobs.matchPending, (state) => {
            state.isLoading.jobs = true;
        })
        builder.addMatcher(logworkApi.endpoints.getJobs.matchRejected, (state) => {
            state.isLoading.jobs = false;
        })
        builder.addMatcher(logworkApi.endpoints.getJobs.matchFulfilled, (state, action) => {

            // const primaryJob = action.payload.jobs.find((job: Job) => job.isPrimary);
            const jobsForDays: Record<number, Job | null> = {};

            if (action.payload.jobs.length === 1) {
                const singleJob = action.payload.jobs[0];
                for (let i = 0; i < 7; i++) {
                    jobsForDays[i] = singleJob;
                }
            } else {
                for (let i = 0; i < 7; i++) {
                    jobsForDays[i] = null;
                }
            }

            state.selectedJobs = {
                manualHours: { ...jobsForDays },
                piecework: { ...jobsForDays },
                hours: { ...jobsForDays },
                addition: { ...jobsForDays },
            };

            action.payload.jobs.forEach((job: Job) => {
                if (job.childProjects.length > 0) {
                    Object.assign(state.childProjects, {
                        [job.jobId]: [...job.childProjects]
                    });
                }
            });

            state.jobs = action.payload.jobs
            state.jobsSettings = action.payload.settings
            state.firstJobYear = action.payload.firstJobYear
            state.isLoading.jobs = false;
        })
        builder.addMatcher(logworkApi.endpoints.getLoggedHours.matchPending, (state) => {
            state.isLoading.loggedWork = true;
        })
        builder.addMatcher(logworkApi.endpoints.getLoggedHours.matchRejected, (state) => {
            state.isLoading.loggedWork = false;
        })
        builder.addMatcher(logworkApi.endpoints.getLoggedHours.matchFulfilled, (state, action) => {
            state.loggedWork = action.payload.loggedWork
            state.summaryStats = action.payload.summary
            state.prevSummaryStats = action.payload.prePeriodSummary
            state.loggedWorkChildProjects = action.payload.childProjects
            state.loggedWorkJobs = action.payload.jobs
            state.isLoading.loggedWork = false
        }),
        builder.addMatcher(logworkApi.endpoints.getCalendarVariables.matchPending, (state) => {
            state.isLoading.calendar = true;
        })
        builder.addMatcher(logworkApi.endpoints.getCalendarVariables.matchRejected, (state) => {
            state.isLoading.calendar = false;
        })
        builder.addMatcher(logworkApi.endpoints.getCalendarVariables.matchFulfilled, (state, action) => {
            
            if (action.payload.shifts) {
                const logged0 = action.payload.shifts.filter((shift: CalendarShift) => shift.logged === 0)
                state.shifts = logged0
            }
            if (action.payload.availability) {
                state.availabilities = action.payload.availability
            }
            if (action.payload.nationalHolidays) {
                state.holidays = action.payload.nationalHolidays
            }
            if (action.payload.trips) {
                state.trips = action.payload.trips
            }
            if (action.payload.vacation) {
                state.vacation = action.payload.vacation
            }

            state.isLoading.calendar = false;
        })
    }
})

export default logworkSlice.reducer;
export const { setJobs, setLoggedWork, setSelectedJobs, setSelectedJob, setSelectedWeekData, setResetTable, setIsSidebarOpened, handleLogWorkErrors, setAsideWidth, setChoosedLoggedWork, setIsRegisterTrackerActive, setIsDeleteTrackerActive, setIsFileAttachmentError, setSelectedDate, setWorkHourId, setLoggedItemsToDelete } = logworkSlice.actions;
export const storeJobs = (state: RootState) => state.logwork.jobs;
export const storeLoggedWork = (state: RootState) => state.logwork.loggedWork;
export const storeSelectedJob = (state: RootState) => state.logwork.selectedJob;
export const storeSelectedJobs = (state: RootState) => state.logwork.selectedJobs;
export const storeResetTable = (state: RootState) => state.logwork.resetTable;
export const storeSelectedWeekData = (state: RootState) => state.logwork.selectedWeekData;
export const storeLoggedWorkChildProjects = (state: RootState) => state.logwork.loggedWorkChildProjects;
export const storeShifts = (state: RootState) => state.logwork.shifts;
export const storeIsSidebarOpened = (state: RootState) => state.logwork.isSidebarOpened;
export const storeIsLogWorkHoursErrors = (state: RootState) => state.logwork.isLogWorkHoursErrors;
export const storeChildProjects = (state: RootState) => state.logwork.childProjects;
export const storeSummaryStats = (state: RootState) => state.logwork.summaryStats;
export const storePrevSummaryStats = (state: RootState) => state.logwork.prevSummaryStats;
export const storeAvailabilities = (state: RootState) => state.logwork.availabilities;
export const storeHolidays = (state: RootState) => state.logwork.holidays;
export const storeTrips = (state: RootState) => state.logwork.trips;
export const storeVacation = (state: RootState) => state.logwork.vacation;
export const storeAsideWidth = (state: RootState) => state.logwork.asideWidth;
export const storeChoosedLoggedWork = (state: RootState) => state.logwork.choosedLoggedWork;
export const storeIsLoading = (state: RootState) => state.logwork.isLoading;
export const storeLoggedWorkById = (state: RootState) => state.logwork.loggedWorkById;
export const storeIsRegisterTrackerActive = (state: RootState) => state.logwork.isRegisterTrackerActive;
export const storeIsDeleteTrackerActive = (state: RootState) => state.logwork.isDeleteTrackerActive;
export const storeLoggedWorkJobs = (state: RootState) => state.logwork.loggedWorkJobs;
export const storeJobsSettings = (state: RootState) => state.logwork.jobsSettings;
export const storeIsFileAttachmentError = (state: RootState) => state.logwork.isFileAttachmentError;
export const storeSelectedDate = (state: RootState) => state.logwork.selectedDate;
export const storeWorkHourId = (state: RootState) => state.logwork.workHourId;
export const storeLoggedItemsToDelete = (state: RootState) => state.logwork.loggedItemsToDelete;
export const storeFirstJobYear = (state: RootState) => state.logwork.firstJobYear
