import {
    Box,
    Checkbox,
    Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Radio,
    RadioGroup,
    Skeleton,
    Text,
    Textarea,
    VStack,
} from '@chakra-ui/react'
import { useGetPostInfoQuery, useSetJobApplyCandidateMutation } from '@core/app/api/postsApi'
import { ApplyJobSettings, ScreeningQuestion } from '@core/app/types/postsApiType'
import { AppButton } from '@core/components/AppButton'
import { FieldError } from '@core/components/FieldError/FieldError'
import { AutoResizeTextarea } from '@core/components/ResizeTextarea/ResizeTextarea'
import { RECAPTCHA } from '@core/utils/constants/constants'
import { useIsApplyDialog } from '@core/utils/contexts/ApplyDialogContext/ApplDialogContext'
import useIsEmployee from '@core/utils/hooks/useIsEmployee'
import { Form, Formik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { ApplyJobDropzone } from '@core/components/ApplyJobDropzone'

interface Props {
    settings: ApplyJobSettings | undefined
    isSettingsLoading: boolean
    isSettingsFetching: boolean
}

interface IAttachment {
    name: string
    ext: string
    base64: string
}

interface FormValues {
    applicationText: string
    terms: boolean
    anonymous: boolean
    reasonText?: string
    attachments: IAttachment[]
}

export const ApplicationText = ({ settings, isSettingsLoading }: Props) => {
    const [applyJob, { isLoading }] = useSetJobApplyCandidateMutation()
    const { id } = useParams()
    const [attachments, setAttachments] = useState<
        {
            base64: string
            ext: string
            name: string
        }[]
    >([])
    const { isEmployee } = useIsEmployee()
    const [attachmentsError, setAttachmentsError] = useState<IAttachment[]>([])
    const postId = location.pathname.includes('/job/')
        ? id
        : localStorage.getItem('apply_dialog') && JSON.parse(localStorage.getItem('apply_dialog')!).id
    const { data: postInfo } = useGetPostInfoQuery({
        id: postId,
    })
    const initialValues: FormValues = {
        applicationText: '',
        terms: false,
        anonymous: false,
        reasonText: '',
        attachments: [],
    }

    const [screeningQuestions, setScreeningQuestions] = useState<ScreeningQuestion[]>([])
    const [symbolsCount, setSymbolsCount] = useState<number>(0)
    const recaptchaRef = useRef<ReCAPTCHA>(null)
    const { t } = useTranslation()
    const { close } = useIsApplyDialog()
    const [questionsError, setQuestionsError] = useState<boolean>(false)

    const submitHandler = async (values: FormValues, screeningQuestions: any, setSubmitting: Function) => {
        recaptchaRef.current?.reset()
        let token = await recaptchaRef.current?.executeAsync()
        if (postId && token) {
            try {
                setAttachmentsError([])
                await applyJob({
                    id: postId,
                    data: {
                        anonymousApplication: values.anonymous,
                        applicationText: settings?.fields?.applicationText.visible ? values.applicationText : undefined,
                        screeningQuestions,
                        anonymousReason: values.reasonText,
                        'g-recaptcha-response': token,
                        attachments: attachments,
                    },
                }).unwrap()

                close()
                localStorage.setItem('after_apply', '1')
                localStorage.setItem('after_apply_id', postId)
                localStorage.setItem('after_apply_title', postInfo?.data.ad.JobTitle || '')
                localStorage.removeItem('apply_path')
                window.dispatchEvent(new Event('ra-input-job-application-apply'))
                setTimeout(() => {
                    location.href = '/profile'
                }, 1000)
            } catch (error: any) {
                if (error.data?.data?.errors?.screeningQuestions) {
                    setQuestionsError(true)
                } else if (
                    error.data?.data?.errors?.attachments &&
                    Array.isArray(error.data?.data?.errors?.attachments)
                ) {
                    setAttachmentsError(error.data.data.errors.attachments)
                } else if (
                    error.data?.data?.errors?.attachments &&
                    !Array.isArray(error.data.data.errors.attachments)
                ) {
                    const attachmentsError = error.data.data.errors.attachments
                    const errs = Array(attachments.length)
                        .fill(null)
                        .map((item, idx) => {
                            return attachmentsError[idx] ? attachmentsError[idx] : null
                        })
                    // @ts-ignore
                    setAttachmentsError(errs)
                } else {
                    console.error(error)
                }
            } finally {
                setSubmitting(false)
            }
        }
    }

    useEffect(() => {
        settings?.screeningQuestions && setScreeningQuestions(settings?.screeningQuestions)
    }, [settings])

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
                applicationText: Yup.string().when([], {
                    is: () => settings?.fields.applicationText.mandatory,
                    // is: () => settings?.requiredFields.applicationText,
                    then: Yup.string().required(`${t('6879')}`),
                    otherwise: Yup.string().notRequired(),
                }),
                anonymous: Yup.bool(),
                reasonText: Yup.string().when(['anonymous'], {
                    is: (anonymous: boolean) => anonymous,
                    then: Yup.string().required(`${t('9542')}`),
                    otherwise: Yup.string().notRequired(),
                }),
            })}
            onSubmit={(values, { setSubmitting }) => {
                const questionsObject = {}

                screeningQuestions.map((item: any) => {
                    Object.assign(questionsObject, {
                        [item.AdPreScreeningID]: {
                            questionId: item.AdPreScreeningID,
                            answer: item.Choosed === 0,
                        },
                    })
                })

                submitHandler(values, questionsObject, setSubmitting)
            }}
        >
            {({ values, handleSubmit, handleChange, handleBlur, setFieldValue, isSubmitting, errors, touched }) => {
                return (
                    <Form onSubmit={handleSubmit} style={{ marginTop: '16px' }}>
                        <ReCAPTCHA
                            sitekey={RECAPTCHA}
                            ref={recaptchaRef}
                            size={'invisible'}
                            onExpired={() => {
                                recaptchaRef.current?.reset()
                            }}
                        />
                        <Flex flexDirection={'column'} gap={5}>
                            {isSettingsLoading ? (
                                <Skeleton w={'full'} h={'109px'} />
                            ) : // ) : settings?.requiredFields?.applicationText ? (
                            settings?.fields?.applicationText.visible ? (
                                <>
                                    <FormControl isInvalid={!!errors.applicationText && touched.applicationText}>
                                        <FormLabel>
                                            <HStack>
                                                <Text fontSize={'sm'} fontWeight={'medium'} color={'text.main'}>
                                                    {t('3398')}
                                                    {settings?.fields?.applicationText.mandatory && '*'}
                                                </Text>
                                                <Text fontSize={'sm'} color={'text.thirdly'}>
                                                    ({t('5339')} 1500) - {1500 - symbolsCount}
                                                </Text>
                                            </HStack>
                                        </FormLabel>
                                        <AutoResizeTextarea
                                            size={'lg'}
                                            //6649
                                            //7124
                                            placeholder={`${t('7124')}*`}
                                            fontSize={'sm'}
                                            onChange={(e: any) => {
                                                setSymbolsCount(e.target.value?.length)
                                                setFieldValue('applicationText', e.target.value)
                                            }}
                                            name={'applicationText'}
                                            value={values.applicationText}
                                            maxLength={1500}
                                        />
                                        <FormErrorMessage>
                                            {errors.applicationText &&
                                                touched.applicationText &&
                                                errors.applicationText}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <ApplyJobDropzone
                                        description={t('5385')}
                                        maxFiles={5}
                                        accept={{
                                            'application/msword': ['.doc', '.dotx', '.docm'],
                                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                                                '.docx',
                                            ],
                                            'application/pdf': ['.pdf'],
                                            'image/gif': ['.gif'],
                                            'image/tiff': ['.tif', '.tiff'],
                                            'image/jpeg': ['.jpg', '.jpeg'],
                                            'image/png': ['.png'],
                                            'image/svg+xml': ['.svg'],
                                            'image/vnd.adobe.photoshop': ['.psd'],
                                            'application/vnd.ms-excel': ['.xls'],
                                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
                                                '.xlsx',
                                                '.xlsm',
                                                '.xltx',
                                            ],
                                            'application/vnd.apple.numbers': ['.numbers'],
                                            'application/vnd.ms-powerpoint': ['.ppt', '.pps', '.pptm'],
                                            'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                                                ['.pptx', '.potx'],
                                            'application/vnd.apple.keynote': ['.key'],
                                            'application/vnd.oasis.opendocument.text': ['.odt'],
                                            'application/zip': ['.zip', '.rar'],
                                            'application/json': ['.json'],
                                            'text/plain': ['.txt', '.csv'],
                                            'text/rtf': ['.rtf'],
                                            'application/vnd.ms-outlook': ['.msg', '.eml'],
                                            'application/octet-stream': ['.dat', '.nda', '.mov', '.mp4'],
                                            'application/vnd.apple.pages': ['.pages'],
                                            'application/postscript': ['.eps', '.ai'],
                                        }}
                                        type={'attachments'}
                                        onUpload={(uploadedFiles) => {
                                            setAttachmentsError([])
                                            const formattedFiles = uploadedFiles.map((file) => ({
                                                base64: file.base64,
                                                ext: file.ext,
                                                name: file.name,
                                            }))
                                            if (attachments.length + formattedFiles.length > 5) {
                                                return
                                            }
                                            setAttachments((prevAttachments) => {
                                                // if (prevAttachments.length + formattedFiles.length > 5) {
                                                //     return prevAttachments;
                                                // }
                                                console.log('prevAttachments', prevAttachments)
                                                return [...prevAttachments, ...formattedFiles]
                                            })
                                        }}
                                        onUploadAttachments={(files) => {
                                            setAttachmentsError([])
                                            const formattedFiles = files.map((file) => ({
                                                base64: file.base64,
                                                ext: file.ext,
                                                name: file.name,
                                            }))

                                            setAttachments((prevAttachments) => {
                                                // if (prevAttachments.length + formattedFiles.length > 5) {
                                                //     return prevAttachments;
                                                // }
                                                const newAttachments = [...prevAttachments, ...formattedFiles].reduce<
                                                    IAttachment[]
                                                >((acc, item) => {
                                                    if (!acc.some((el: IAttachment) => el.name === item.name)) {
                                                        acc.push(item)
                                                    }
                                                    return acc
                                                }, [])
                                                return newAttachments
                                            })
                                        }}
                                        applyFiles={attachments}
                                        onApplyFilesDelete={(file) => {
                                            setAttachmentsError([])
                                            setAttachments((prevAttachments) =>
                                                prevAttachments.filter((item) => item.name !== file.name)
                                            )
                                        }}
                                        isAlternative={true}
                                        attachmentErrors={attachmentsError}
                                    />
                                </>
                            ) : null}
                            <FormControl isInvalid={questionsError}>
                                {isSettingsLoading ? (
                                    <Skeleton w={'full'} h={'52px'} />
                                ) : screeningQuestions?.length > 0 ? (
                                    <VStack gap={2} w={'full'} alignItems={'start'}>
                                        {screeningQuestions.map((question) => (
                                            <Box
                                                key={question.AdPreScreeningID}
                                                display={'flex'}
                                                flexDirection={'column'}
                                                gap={3}
                                                w={'full'}
                                            >
                                                <Text fontSize={'sm'} fontWeight={'semibold'} color={'text.main'}>
                                                    {question.Question}
                                                </Text>
                                                <RadioGroup
                                                    onChange={(value) => {
                                                        setQuestionsError(false)
                                                        let questionObject = screeningQuestions.find(
                                                            (object) =>
                                                                object.AdPreScreeningID === question.AdPreScreeningID
                                                        )
                                                        if (questionObject) {
                                                            questionObject = {
                                                                ...questionObject,
                                                                Choosed: +value,
                                                            }
                                                            let newQuestionArray = screeningQuestions.map((item) =>
                                                                item.AdPreScreeningID ===
                                                                questionObject?.AdPreScreeningID
                                                                    ? questionObject
                                                                    : item
                                                            )
                                                            setScreeningQuestions(newQuestionArray)
                                                        }
                                                    }}
                                                    // @ts-ignore
                                                    value={question.Choosed}
                                                >
                                                    <HStack>
                                                        <Radio value={0} fontSize={'sm'}>
                                                            <Text fontSize={'sm'}>{t('128')}</Text>
                                                        </Radio>
                                                        <Radio value={1} fontSize={'sm'}>
                                                            <Text fontSize={'sm'}>{t('129')}</Text>
                                                        </Radio>
                                                    </HStack>
                                                </RadioGroup>
                                                {question.Answer === question.Choosed ? (
                                                    <FieldError text={question.Response} />
                                                ) : null}

                                                <Divider />
                                            </Box>
                                        ))}
                                    </VStack>
                                ) : null}
                                <FormErrorMessage>
                                    <FieldError text={`${t('7098')}`} />
                                </FormErrorMessage>
                            </FormControl>
                            {isSettingsLoading ? (
                                <Skeleton w={'full'} h={'109px'} />
                            ) : settings?.settings?.anonymousApplication ? (
                                <FormControl>
                                    <Checkbox
                                        checked={values.anonymous}
                                        onChange={(e) => setFieldValue('anonymous', e.target.checked)}
                                    >
                                        <Text fontSize={'sm'} color={'text.main'}>
                                            {t('8578')}
                                        </Text>
                                    </Checkbox>
                                </FormControl>
                            ) : null}

                            {values.anonymous && (
                                <FormControl isInvalid={!!errors.reasonText && touched.reasonText}>
                                    <FormLabel>
                                        <HStack>
                                            <Text fontSize={'sm'} fontWeight={'medium'} color={'text.main'}>
                                                {t('2371')}*
                                            </Text>
                                            {/*<Text*/}
                                            {/*    fontSize={'sm'}*/}
                                            {/*    fontWeight={'medium'}*/}
                                            {/*    color={'text.thirdly'}*/}
                                            {/*    textTransform={'lowercase'}*/}
                                            {/*>*/}
                                            {/*    ({t('6149')})*/}
                                            {/*</Text>*/}
                                        </HStack>
                                    </FormLabel>
                                    <Textarea
                                        size={'lg'}
                                        name={'reasonText'}
                                        placeholder={`${t('6636')}`}
                                        fontSize={'sm'}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <FormErrorMessage className={'Errors__reasonText'}>
                                        {errors.reasonText && errors.reasonText}
                                    </FormErrorMessage>
                                </FormControl>
                            )}
                            <FormControl>
                                <AppButton
                                    translate="no"
                                    title={t('1111')}
                                    type={'submit'}
                                    variant={'primary'}
                                    id={'ra-input-job-application-apply'}
                                    isLoading={isLoading}
                                />
                            </FormControl>
                        </Flex>
                    </Form>
                )
            }}
        </Formik>
    )
}
