import {
    Avatar,
    Box,
    Button,
    Container,
    Divider,
    Flex,
    HStack,
    IconButton,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Skeleton,
    Text,
    useMediaQuery,
    useTheme,
    VStack,
} from '@chakra-ui/react'

import avatarDefault from '@core/assets/images/avatar_default.svg'

import { useLogoutMutation } from '@core/app/api/authApi'
import { useGetHeaderInfoQuery } from '@core/app/api/profileApi'
import { setResetForm } from '@core/app/slices/profile'
import { Localization } from '@core/app/types/globalApiType'
import { IconMenu } from '@core/assets/icons/menu'
import { AppButton } from '@core/components/AppButton'
import CookieConsent from '@core/components/CookieConsent/CookieConsent'
import { Language } from '@core/components/Header/Language/Language'
import { MenuItem } from '@core/components/Header/MenuItem/MenuItem'
import { LanguageModal } from '@core/components/Header/UnauthorizedHeader/LanguageModal/LanguageModal'
import { UnauthorizedHeader } from '@core/components/Header/UnauthorizedHeader/UnauthorizedHeader'
import { useAppTheme } from '@core/utils/contexts/ThemeContext/ThemeContext'
import { hexColorEffect } from '@core/utils/helpers/hexColorEffect'
import { useAppDispatch } from '@core/utils/hooks/hook'
import FeedbackTop from '@core/components/Header/FeedbackTop'
import { Dialog } from 'primereact/dialog'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

const baseClass = 'Header'

interface Props {
    isProfilePage?: boolean
    fixedHeader?: boolean
    isFeedbackMenu?: boolean
    setIsFeedbackMenu?: (val: boolean) => void
    selectedFeedback?: number | null
    setSelectedFeedback?: (val: number | null) => void
}

export function Header({
    isProfilePage = false,
    fixedHeader = false,
    isFeedbackMenu = false,
    selectedFeedback = null,
    setIsFeedbackMenu = (v) => {},
    setSelectedFeedback = (v) => {},
}: Props) {
    const dispatch = useAppDispatch()
    const [logout] = useLogoutMutation()
    const { data: headerInfo, isLoading, isSuccess } = useGetHeaderInfoQuery({})
    const { t } = useTranslation()
    const loc = useLocation()
    const [isDesktop] = useMediaQuery(isProfilePage ? '(min-width: 992px)' : '(min-width: 1230px)', {
        fallback: false,
    })
    const [isOpenedMenu, setIsOpenedMenu] = useState(false)
    const [isOpenedLang, setIsOpenedLang] = useState(false)
    // const [isFeedbackMenu, setIsFeedbackMenu] = useState(false)
    // const [selectedFeedback, setSelectedFeedback] = useState<number | null>(null)

    const isEmployee = useMemo(() => {
        return headerInfo?.data?.Substitute === 1
    }, [headerInfo])

    const { appTheme } = useAppTheme()
    const theme = useTheme()

    const mainThemeBgColor =
        appTheme && appTheme?.newSettings?.general?.HeaderBackground
            ? `#${appTheme?.newSettings?.general?.HeaderBackground}`
            : theme.colors.bg[1]
    const logo = appTheme && appTheme.headerLogo ? appTheme.headerLogo : undefined

    const textColor =
        appTheme && appTheme?.newSettings?.general?.HeaderText
            ? `#${appTheme?.newSettings?.general?.HeaderText}`
            : theme.colors.text.main

    const isLogged = headerInfo && headerInfo.statusCode === 200 && headerInfo.data?.CandidateID
    const [localization, setLocalization] = useState<Localization>({})

    const logoutHandler = async () => {
        try {
            const response = await logout().unwrap()
            localStorage.removeItem('mobile_verification')

            location.href = response.redirectUrl
            localStorage.removeItem('apply_dialog')
            localStorage.removeItem('service_code')
            localStorage.removeItem('is_logged')
            localStorage.removeItem('after_apply')
            localStorage.removeItem('applying_process')
            localStorage.removeItem('after_apply_id')
            localStorage.removeItem('after_apply_title')
            dispatch(setResetForm(true))
        } catch (error) {}
    }

    const localeList = Object.values(headerInfo?.locale?.list || {})

    useEffect(() => {
        if (appTheme && appTheme?.localization) {
            setLocalization(appTheme?.localization)
        }
    }, [appTheme])

    if (isLoading || !headerInfo) {
        return <Skeleton height="100px" /> // Adjust the height as needed
    }

    const handleRedirect = () => {
        window.location.href = '/profile'
    }

    return (
        <Box
            className={baseClass}
            as={'header'}
            // py={3}
            bg={mainThemeBgColor ? mainThemeBgColor : theme.colors.bg[1]}
            boxShadow={'0px 1px 6px 0px rgba(0,0,0,0.15)'}
            position={fixedHeader ? 'fixed' : 'relative'}
            minHeight={'84px'}
            top={fixedHeader ? 0 : 'auto'}
            left={fixedHeader ? 0 : 'auto'}
            right={fixedHeader ? 0 : 'auto'}
            zIndex={fixedHeader ? 1000 : 'auto'}
        >
            {!isDesktop && (
                <>
                    {headerInfo.locale && (
                        <UnauthorizedHeader
                            locale={headerInfo.locale}
                            news={headerInfo?.menu?.newsfeed}
                            signup={headerInfo?.menu?.signUp}
                            contacts={headerInfo?.menu?.contactUs}
                            opened={isOpenedMenu}
                            onClose={() => setIsOpenedMenu(false)}
                            openLang={() => {
                                setIsOpenedMenu(false)
                                setIsOpenedLang(true)
                            }}
                        />
                    )}
                    <LanguageModal
                        locale={headerInfo.locale}
                        opened={isOpenedLang}
                        onClose={() => setIsOpenedLang(false)}
                    />
                </>
            )}
            <>
                {isSuccess && (
                    <FeedbackTop
                        isProfilePage={isProfilePage}
                        isMenuFeedback={isFeedbackMenu}
                        onClose={() => {
                            setIsFeedbackMenu(false)
                            setSelectedFeedback(null)
                        }}
                        setSelectedFeedback={setSelectedFeedback}
                        selectedFeedback={selectedFeedback}
                    />
                )}
                <Container
                    py={3}
                    maxW={{
                        base: 'container.md',
                        lg: isProfilePage ? 'container.lg' : 'container.md',
                        xl: 'container.xl',
                    }}
                >
                    <HStack w={'full'} justifyContent={'space-between'}>
                        <Box as={'a'} minW="0" className={'header-logo-link'}>
                            <Flex alignItems="center" minHeight="60px" className={'header-flex'}>
                                {logo ? (
                                    <Box as="a" href={`/`} className={'header-logo-link'}>
                                        <Image
                                            className={'header-logo'}
                                            src={logo}
                                            maxW={{ base: '98px', xl: '160px' }}
                                            maxH={'60px'}
                                            alt={`${t('7840')}`}
                                        />
                                    </Box>
                                ) : (
                                    <Box as="a" href={`/`} className={'header-text-link'}>
                                        <Text
                                            fontFamily="Open Sans"
                                            fontSize="20px"
                                            fontWeight="600"
                                            lineHeight="28px"
                                            textAlign="left"
                                            color={textColor}
                                            isTruncated
                                            noOfLines={1}
                                            overflow="hidden"
                                            textOverflow="ellipsis"
                                            whiteSpace="nowrap"
                                        >
                                            {appTheme?.corporation?.Name || t('Company')}
                                        </Text>
                                    </Box>
                                )}
                            </Flex>
                        </Box>
                        <HStack gap={'16px'}>
                            <HStack gap={'16px'} display={{ base: 'none', xl: 'flex' }}>
                                {!isLogged && appTheme?.corporation?.Webpage && (
                                    <MenuItem
                                        target={'_blank'}
                                        title={t('141')}
                                        href={
                                            appTheme.corporation.Webpage.includes('http')
                                                ? appTheme.corporation.Webpage
                                                : `https://${appTheme.corporation.Webpage}`
                                        }
                                        isWebpageLink={true}
                                    />
                                )}
                                <MenuItem
                                    title={localization[23] ? localization[23] : t('2495')}
                                    href={'/jobs'}
                                    //  active={loc.pathname === '/jobs'}
                                />
                                {/* {isLogged &&
                                isEmployee &&
                                (location.href.includes('recmanpage.local') || location.href.includes('recmandev')) ? (
                                <MenuItem
                                    title={t('2963')}
                                    href={'/employee'}
                                //  active={loc.pathname === '/contacts'}
                                />
                            ) : null} */}
                                {headerInfo?.menu?.newsfeed && (
                                    <MenuItem
                                        title={t('5137')}
                                        href={'/newsfeed'}
                                        //  active={loc.pathname === '/newsfeed'}
                                    />
                                )}
                                {headerInfo?.menu?.contactUs && (
                                    <MenuItem
                                        title={t('2762')}
                                        href={'/contacts'}
                                        //  active={loc.pathname === '/contacts'}
                                    />
                                )}
                                {isLogged && headerInfo?.menu.applications ? (
                                    <MenuItem
                                        title={t('3180')}
                                        href={'/profile/applications'}
                                        // active={loc.pathname === '/profile/applications'}
                                    />
                                ) : null}
                                {isLogged && headerInfo?.menu.agreements ? (
                                    <MenuItem
                                        title={t('3339')}
                                        href={'/profile/agreement'}
                                        // active={loc.pathname === '/profile/agreement'}
                                    />
                                ) : null}
                            </HStack>

                            <HStack pl="0px" gap={{ base: 2, xl: 5 }}>
                                {/*{(isDesktop && !isLogged) && localeList.length > 1 ? (*/}
                                {/*    <Box pl="20px" className={'header_locale_box'}>*/}
                                {/*        <Language locale={headerInfo.locale} />*/}
                                {/*    </Box>*/}
                                {/*) : null}*/}

                                {isDesktop ? (
                                    <Skeleton
                                        isLoaded={!isLoading}
                                        minW={{
                                            base: headerInfo?.menu?.signUp ? '82px' : '82px',
                                            xl: headerInfo?.menu?.signUp ? '75px' : '75px',
                                        }}
                                        h={{ base: '36px', xl: '40px' }}
                                        borderRadius={32}
                                    >
                                        {isLogged ? (
                                            <Popover
                                                isLazy
                                                placement={'bottom-end'}
                                                autoFocus={false}
                                                arrowPadding={8}
                                                closeOnBlur={true}
                                                trigger={isDesktop ? 'hover' : 'click'}
                                            >
                                                <PopoverTrigger>
                                                    <Box
                                                        onClick={handleRedirect}
                                                        display={'block'}
                                                        rounded={32}
                                                        py={2}
                                                        // px={4}
                                                        cursor={'pointer'}
                                                        role="button"
                                                        aria-label={`${t('1831')}`}
                                                    >
                                                        <HStack
                                                        // gap={3}
                                                        >
                                                            <Text
                                                                fontSize={'sm'}
                                                                fontWeight={'semibold'}
                                                                color={textColor}
                                                            >
                                                                {headerInfo?.data.FirstName}
                                                            </Text>
                                                            <Avatar
                                                                w={6}
                                                                h={6}
                                                                icon={
                                                                    <Image
                                                                        src={
                                                                            headerInfo?.data.ProfilePicture ||
                                                                            avatarDefault
                                                                        }
                                                                        alt={`${t('6684')}`}
                                                                        width="24px"
                                                                        height="24px"
                                                                        borderRadius="50%"
                                                                        objectFit="cover"
                                                                    />
                                                                }
                                                            />
                                                        </HStack>
                                                    </Box>
                                                </PopoverTrigger>
                                                <PopoverContent
                                                    mt={2}
                                                    maxW={'200px'}
                                                    borderColor={
                                                        appTheme && appTheme?.newSettings?.general?.HeaderBackground
                                                            ? `#${appTheme?.newSettings?.general?.HeaderBackground}`
                                                            : theme.colors.bg[1]
                                                    }
                                                >
                                                    <PopoverArrow
                                                        boxShadow={'0px 8px 12px 0px rgba(0, 0, 0, 0.08);'}
                                                        bg={
                                                            appTheme && appTheme?.newSettings?.general?.HeaderBackground
                                                                ? `#${appTheme?.newSettings?.general?.HeaderBackground}`
                                                                : theme.colors.bg[1]
                                                        }
                                                    />
                                                    <PopoverBody
                                                        bg={
                                                            appTheme && appTheme?.newSettings?.general?.HeaderBackground
                                                                ? `#${appTheme?.newSettings?.general?.HeaderBackground}`
                                                                : theme.colors.bg[1]
                                                        }
                                                        boxShadow={'0px 8px 12px 0px rgba(0, 0, 0, 0.08);'}
                                                    >
                                                        {/* {(isDesktop || (!isDesktop && isLoggngth ed)) && localeList.le> 1 ? (
                                                        <Box w="100%"
                                                            py={1.5}
                                                            px={3}
                                                            className={'header_locale_box'}>
                                                            <LanguageHeader locale={headerInfo.locale} />
                                                        </Box>
                                                    ) : null} */}

                                                        <Box
                                                            as={'a'}
                                                            display={'block'}
                                                            href={'/profile'}
                                                            py={1.5}
                                                            px={3}
                                                            cursor={'pointer'}
                                                            borderRadius={'4px'}
                                                            role="button"
                                                            aria-label={`${t('4647')}`}
                                                        >
                                                            <Text
                                                                fontSize={'sm'}
                                                                fontWeight={'medium'}
                                                                _hover={{
                                                                    color: hexColorEffect(textColor, 60),
                                                                }}
                                                                color={
                                                                    appTheme?.newSettings?.general?.HeaderText
                                                                        ? `#${appTheme?.newSettings?.general?.HeaderText}`
                                                                        : 'text.secondary'
                                                                }
                                                                textTransform={'lowercase'}
                                                                _firstLetter={{ textTransform: 'uppercase' }}
                                                            >
                                                                {t('4647')}
                                                            </Text>
                                                        </Box>
                                                        <Box
                                                            as={'a'}
                                                            display={'block'}
                                                            href={'/profile/settings'}
                                                            py={1.5}
                                                            px={3}
                                                            cursor={'pointer'}
                                                            borderRadius={'4px'}
                                                            role="button"
                                                            aria-label={`${t('420')}`}
                                                        >
                                                            <Text
                                                                fontSize={'sm'}
                                                                fontWeight={'medium'}
                                                                _hover={{
                                                                    color: hexColorEffect(textColor, 60),
                                                                }}
                                                                color={
                                                                    appTheme?.newSettings?.general?.HeaderText
                                                                        ? `#${appTheme?.newSettings?.general?.HeaderText}`
                                                                        : 'text.secondary'
                                                                }
                                                                textTransform={'lowercase'}
                                                                _firstLetter={{ textTransform: 'uppercase' }}
                                                            >
                                                                {t('420')}
                                                            </Text>
                                                        </Box>
                                                        <Box
                                                            as={'button'}
                                                            display={'block'}
                                                            onClick={() => {
                                                                setIsFeedbackMenu(true)
                                                                setSelectedFeedback(0)
                                                            }}
                                                            py={1.5}
                                                            px={3}
                                                            cursor={'pointer'}
                                                            borderRadius={'4px'}
                                                            role="button"
                                                            aria-label={`${t('420')}`}
                                                        >
                                                            <Text
                                                                fontSize={'sm'}
                                                                fontWeight={'medium'}
                                                                _hover={{
                                                                    color: hexColorEffect(textColor, 60),
                                                                }}
                                                                color={
                                                                    appTheme?.newSettings?.general?.HeaderText
                                                                        ? `#${appTheme?.newSettings?.general?.HeaderText}`
                                                                        : 'text.secondary'
                                                                }
                                                                textTransform={'lowercase'}
                                                                _firstLetter={{ textTransform: 'uppercase' }}
                                                            >
                                                                {t('9477')}
                                                            </Text>
                                                        </Box>
                                                        <Divider my={2} />
                                                        <Box
                                                            as={'a'}
                                                            onClick={() => logoutHandler()}
                                                            display={'block'}
                                                            py={1.5}
                                                            px={3}
                                                            cursor={'pointer'}
                                                            borderRadius={'4px'}
                                                            role="button"
                                                            aria-label={`${t('1446')}`}
                                                        >
                                                            <Text
                                                                fontSize={'sm'}
                                                                fontWeight={'medium'}
                                                                _hover={{
                                                                    color: hexColorEffect(textColor, 60),
                                                                }}
                                                                color={
                                                                    appTheme?.newSettings?.general?.HeaderText
                                                                        ? `#${appTheme?.newSettings?.general?.HeaderText}`
                                                                        : 'text.secondary'
                                                                }
                                                                _firstLetter={{ textTransform: 'uppercase' }}
                                                                textTransform={'lowercase'}
                                                            >
                                                                {t('1446')}
                                                            </Text>
                                                        </Box>
                                                    </PopoverBody>
                                                </PopoverContent>
                                            </Popover>
                                        ) : (
                                            <HStack gap={{ base: 0, xl: '16px' }}>
                                                {/* <AppButton
                                                variant={'ghost'}
                                                display={'block'}
                                                color={textColor}
                                                px={4}
                                                py={2}
                                                height={{
                                                    base: '36px',
                                                    xl: '40px',
                                                }}
                                                rounded={32}
                                                cursor={'pointer'}
                                                fontWeight={'semibold'}
                                                fontSize={'sm'}
                                                _hover={{
                                                    color: hexColorEffect(textColor, 60),
                                                }}
                                                transition={'all .2s ease-in-out'}
                                                onClick={() => {
                                                    location.href = `${window.location.origin}/login`
                                                }}
                                                title={t('1890')}
                                            /> */}

                                                <MenuItem title={t('1890')} href={`${window.location.origin}/login`} />

                                                {headerInfo?.menu?.signUp ? (
                                                    <AppButton
                                                        translate="no"
                                                        variant={'primary'}
                                                        size={{
                                                            base: 'base',
                                                            xl: 'xl',
                                                        }}
                                                        onClick={() =>
                                                            (location.href = `${window.location.origin}/sign-up`)
                                                        }
                                                        title={localization[17] ? localization[17] : t('2496')}
                                                    />
                                                ) : null}
                                            </HStack>
                                        )}
                                    </Skeleton>
                                ) : !isLogged ? (
                                    <Flex alignItems={'center'} gap={4}>
                                        {headerInfo?.menu?.signUp ? (
                                            <AppButton
                                                translate="no"
                                                variant={'primary'}
                                                size={{
                                                    base: 'base',
                                                    xl: 'xl',
                                                }}
                                                onClick={() => (location.href = `${window.location.origin}/sign-up`)}
                                                title={localization[17] ? localization[17] : t('2496')}
                                            />
                                        ) : null}
                                        <IconButton
                                            variant={'default'}
                                            icon={<IconMenu fill={'#4f4f4f'} />}
                                            aria-label={'burger menu'}
                                            onClick={() => setIsOpenedMenu(true)}
                                        />
                                    </Flex>
                                ) : null}
                            </HStack>
                            {/*{(isDesktop && isLogged) && localeList.length > 1 ? (*/}
                            {/*  <Box pl="1px" className={'header_locale_box'}>*/}
                            {/*      <Language locale={headerInfo.locale} />*/}
                            {/*  </Box>*/}
                            {/*) : null}*/}
                            {isDesktop && !isLogged && localeList.length > 1 ? (
                                <Box pl="1px" className={'header_locale_box'}>
                                    <Language locale={headerInfo.locale} />
                                </Box>
                            ) : null}
                        </HStack>
                    </HStack>
                </Container>
            </>
            <CookieConsent isOpenedMenu={isOpenedMenu} termsAccepted={headerInfo?.termsAccepted} />
        </Box>
    )
}
