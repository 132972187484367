import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Box, Button, Flex, Link } from '@chakra-ui/react'
import { IconCookie } from '@core/assets/icons/cookie'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { AppButton } from '@core/components/AppButton'

interface CookieConsentModalProps {
    termsAccepted: boolean | null | undefined
    isOpenedMenu: boolean
}

const CookieConsentModal: React.FC<CookieConsentModalProps> = ({ isOpenedMenu, termsAccepted }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const [showModal, setShowModal] = useState(false)

    const handleAccept = () => {
        localStorage.setItem('cookieAccepted', 'true')
        setShowModal(false)
    }

    useEffect(() => {
        const isCookieAccepted = localStorage.getItem('cookieAccepted') || termsAccepted
        const timer = setTimeout(() => {
            setShowModal(true)
        }, 2000)
        if (isCookieAccepted) {
            setShowModal(false)
            // localStorage.setItem('cookieAccepted', 'true')
            clearTimeout(timer)
        }
        return () => clearTimeout(timer)
    }, [location.pathname, termsAccepted])

    if (!showModal || isOpenedMenu) {
        return null
    }

    return (
        <Box
            position="fixed"
            left="50%"
            transform="translateX(-50%)"
            bottom="90px"
            zIndex="2"
            width={{ base: 'calc(100% - 32px)', md: 'calc(100% - 64px)', lg: '800px' }}
            p="24px"
            bg="white"
            borderRadius="8px"
            display="flex"
            flexWrap={{ base: 'wrap', md: 'nowrap', lg: 'nowrap' }}
            gap="16px"
            boxShadow={'0px 2px 10px rgba(0, 0, 0, 0.15)'}
            border={'1px solid #E5E5E5'}
        >
            <Box>
                <IconCookie />
            </Box>
            <Flex direction={'column'} gap={'8px'} grow={1}>
                <Box fontSize={'20px'} fontWeight={'600'} lineHeight={'28px'} color={'#1A1A1A'}>
                    {t('7419')}
                </Box>
                <Box fontSize={'14px'} color={'#404040'}>
                    {t('7391')}{' '}
                    <Link
                        href={'/cookie-notice'}
                        target={'_blank'}
                        color={'#2F80ED'}
                        textTransform={'lowercase'}
                        fontWeight={'600'}
                    >
                        {t('4637')}
                    </Link>
                    .
                </Box>
            </Flex>
            <Flex direction={'column'} justifyContent={'flex-end'} marginLeft={{ base: 'auto', md: '8px' }}>
                <AppButton translate="no" width={'180px'} title={t('591')} onClick={handleAccept} variant={'primary'} />
            </Flex>
        </Box>
    )
}

const CookieConsent: React.FC<CookieConsentModalProps> = ({ termsAccepted, isOpenedMenu }) => {
    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        setIsMounted(true)
    }, [])

    if (!isMounted || !document?.body) {
        return null
    }

    return (
        <>
            {createPortal(
                <CookieConsentModal isOpenedMenu={isOpenedMenu} termsAccepted={termsAccepted} />,
                document.body
            )}
        </>
    )
}

export default CookieConsent
