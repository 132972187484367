import { ChakraProvider } from '@chakra-ui/react'
import store from '@core/app/store/store'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import '../../index.css'
import theme from '../../theme.js'
import { Page404 } from './404'

import { ThemeProvider } from '@core/utils/contexts/ThemeContext/ThemeContext'
import '@core/utils/i18n'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import '../../index.css'

import ErrorBoundary from '@core/utils/helpers/errorBoundary'

import { IsApplyDialogProvider } from '@core/utils/contexts/ApplyDialogContext/ApplDialogContext'
import { ModalProvider } from '@core/utils/contexts/ModalContext/ModalContextProvider'
import '@core/utils/i18n'

const app = document.getElementById('root')
const root = createRoot(app!)

const router = createBrowserRouter([
    {
        path: process.env.ENV === 'local' ? '' : '*',
        element: (
            <ErrorBoundary fallback={<div>Something went wrong...</div>}>
                <ThemeProvider>
                    <IsApplyDialogProvider>
                        <Page404 />
                    </IsApplyDialogProvider>
                </ThemeProvider>
            </ErrorBoundary>
        ),
    },
])

root.render(
    <>
        <Provider store={store}>
            <ChakraProvider theme={theme}>
                <ModalProvider>
                    <RouterProvider router={router} />
                </ModalProvider>
            </ChakraProvider>
        </Provider>
    </>
)
